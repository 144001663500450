import { l } from '@manychat/manyui'

import { sendUpgradeAnalytics } from 'common/billing/actions/analyticsActions'
import { checkoutSuccessSubscribeClientFlow } from 'common/billing/actions/checkoutSuccessSubscribeClientFlowActions'
import { requestCredentials } from 'common/billing/components/AsyncStripeClientCredentialsView/AsyncStripeClientCredentialsView'
import { RequestCredentialsResult } from 'common/billing/components/AsyncStripeClientCredentialsView/types'
import { StripeCheckoutTypes } from 'common/billing/constants/stripeCheckoutTypes'
import { billingLock } from 'common/billing/helpers/billingLock'
import { AddOnSlug } from 'common/billing/interfaces/addOnTypes'
import { getActiveProProducts } from 'common/billing/selectors/billingSelectors'
import { alert } from 'common/core'
import { Ability } from 'common/core/constants/Ability'
import { ProProduct } from 'common/core/interfaces/products'
import { getInAppCardAttachmentFlags, getIsTrial } from 'common/core/selectors/appSelectors'
import { isCancelError } from 'shared/api/lib/errors/business/local'
import { isStripeError } from 'shared/api/lib/errors/business/stripeError'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import { ManageSubscriptionPayload } from 'shared/api/requests/billingFlex/schemas'
import { createAsyncAction } from 'shared/lib/redux'

import { handleStripeRedirect, redirectToStripe } from './stripeCheckoutActions'

interface ManageSubscriptionResult {
  abilities: Ability[]
  redirectURL?: string | null
}

export const manageSubscription = createAsyncAction<
  ManageSubscriptionResult,
  ManageSubscriptionPayload
>(
  'billing/manageSubscription',
  async (payload) => {
    const release = await billingLock.acquire()

    try {
      const response = await billingFlexApi.manageSubscription({
        body: payload,
      })

      release()
      return response.data
    } catch (error) {
      release()

      throw error
    }
  },
  {
    onBusinessError: (error) => {
      if (billingFlexApi.manageSubscription.isManageSubscriptionError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
    },
  },
)

interface UpgradePayload {
  products?: ProProduct[]
  addOns?: AddOnSlug[]
  checkoutType: StripeCheckoutTypes.TRIAL | StripeCheckoutTypes.UPGRADE_TO_PRO
}

export const upgrade = createAsyncAction<void, UpgradePayload>(
  'billing/upgrade',
  async ({ products = [], addOns = [], checkoutType }, { dispatch, getState }) => {
    const state = getState()
    const productsMapToEnable = Object.fromEntries(products.map((product) => [product, true]))
    const addOnsToEnable = Object.fromEntries(addOns.map((addOn) => [addOn, true]))

    const activeProducts = getActiveProProducts(state)
    const hasActivePlan = Boolean(activeProducts.length)
    const isInAppCardAttachment = getInAppCardAttachmentFlags(state)

    if (hasActivePlan) {
      const hasTrial = getIsTrial(state)

      const prepare = <T extends SafeUnknownObject>(products: T): T | undefined =>
        Object.keys(products).length ? products : undefined

      const result = await dispatch(
        manageSubscription({
          products: prepare(productsMapToEnable),
          addons: prepare(addOnsToEnable),
          end_trial: Boolean(hasTrial && addOns.length),
        }),
      ).unwrap()

      handleStripeRedirect(result.redirectURL)

      alert(
        l.translate('{products, plural, one {Product} other {Products}} enabled', {
          products: products.length + addOns.length,
        }),
        'success',
      )

      return
    }

    if (!isInAppCardAttachment.isEnabled) {
      await dispatch(
        redirectToStripe({
          type: checkoutType,
          products,
          addOns,
        }),
      ).unwrap()

      return
    }

    const confirm: RequestCredentialsResult = await requestCredentials()

    await dispatch(
      checkoutSuccessSubscribeClientFlow({
        stripe: confirm.stripe,
        setup_intent_id: confirm.intent_id,
        products: productsMapToEnable,
        addons: addOnsToEnable,
      }),
    ).unwrap()

    alert('Upgrade successful!', 'success')

    dispatch(sendUpgradeAnalytics({ products, addOns, checkoutType }))
  },
  {
    onError: (error) => isCancelError(error) || isStripeError(error),
  },
)
