import { createSelector, Selector } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import { l } from '@manychat/manyui'

import { AccountDisplayFlag } from 'apps/dashboard/constants/DisplayFlagType'
import { getLangFromCookie } from 'apps/locale/languageCookie'
import {
  IApplicationAction,
  IInstalledApplication,
} from 'common/actions/integrations/Applications/interfaces'
import { FBPolicyEnforcementModalType } from 'common/core/components/FBPolicyEnforcementModal/FBPolicyEnforcementModalType'
import {
  FacebookPolicyEnforcementNotification,
  FacebookPolicyEnforcementState,
  FBPolicyEnforcementNotificationType,
} from 'common/core/components/NotifyBar/interfaces/facebookNotifications'
import { AccountUser } from 'common/core/interfaces/accountUser'
import { ICurrentAccount } from 'common/core/interfaces/currentAccount'
import { LivechatSettingsInfo } from 'common/core/interfaces/liveChatSettingsInfo'
import { IPage } from 'common/core/interfaces/page'
import { Persona } from 'common/core/interfaces/persona'
import { ProductType, ProProduct } from 'common/core/interfaces/products'
import { ISidebarAccount } from 'common/core/interfaces/sideBarAccount'
import { User } from 'common/core/interfaces/user'
import { UserActivity } from 'common/core/interfaces/userActivity'
import AccountModel from 'common/core/models/Account'
import { AccountStatus, AccountTargetPermissions } from 'common/core/models/Account/constants'
import { DefaultAccountTargetPermissions } from 'common/core/models/Account/defaults'
import { isExpiredStatus } from 'common/core/models/Account/helpers'
import { AccountTargetPermissionsNames } from 'common/core/models/Account/names'
import { initialState } from 'common/core/reducers/constants'
import errorTrackingService from 'utils/services/errorTrackingService'
import { AccountFlag } from 'utils/services/featureFlagsService/constants'
import type { UserFlagValues } from 'utils/services/featureFlagsService/interfaces'

export interface IExtendedCurrentAccount extends ICurrentAccount {
  category?: string
  allow_free_branding?: boolean
  allow_api?: boolean
  isPro: boolean
  isVipPro: boolean
  isExpired: boolean
  isFree: boolean
  isTrial: boolean
}

interface PricingLimitInfo {
  subscribers_total: number
  subscribers_limit: number
  MIN_LIMIT_TO_BLOCK: number
}

export const isFetchPricingLimitInfo = (state: RootState): boolean => {
  const accountStatus = getAccountStatus(state)
  // TODO Remove after full refuse of BLOCKED_2020 status
  const isBlocked = isAccountBlocked(state)
  const newPricingStatuses = [AccountStatus.FREE2020, AccountStatus.EXPIRED2020]
  return newPricingStatuses.indexOf(accountStatus) !== -1 || isBlocked
}

export const getBroadcastPermission = (state: RootState): boolean => {
  const permissionNames = [
    AccountTargetPermissionsNames.BROADCAST_FB,
    AccountTargetPermissionsNames.BROADCAST_SMS,
    AccountTargetPermissionsNames.BROADCAST_EMAIL,
    AccountTargetPermissionsNames.BROADCAST_TELEGRAM,
    AccountTargetPermissionsNames.BROADCAST_INSTAGRAM,
    AccountTargetPermissionsNames.BROADCAST_MULTIPLE,
  ]

  const hasPermission = permissionNames.find((name) => {
    return getAccountPermissionByType(state, name)
  })

  return Boolean(hasPermission)
}

export function getAccountStatus(state: RootState): AccountStatus {
  return getCurrentAccount(state).pro_status
}

export function getIsTrial(state: RootState): boolean {
  return getCurrentAccount(state).isTrial
}

export const isAccountBlocked = (state: RootState): boolean => {
  return getCurrentAccount(state).is_blocked
}

export const getShowContactsLimitMessage = createSelector(
  [getAccountStatus, isAccountBlocked],
  (status, isBlocked): boolean => {
    return status === AccountStatus.FREE2020 || isBlocked
  },
)

export const getAccountPermissionsByStatus = (state: RootState) => {
  const accountStatus = getAccountStatus(state)
  if (isAccountBlocked(state)) {
    return DefaultAccountTargetPermissions
  }
  if (!AccountTargetPermissions[accountStatus]) {
    errorTrackingService.trackError(new Error(`Unknown contact status`), {
      extra: { accountStatus },
    })
    return AccountTargetPermissions[AccountStatus.FREE]
  }

  return AccountTargetPermissions[accountStatus]
}

export const getAccountPermissionByType = (
  state: RootState,
  permissionName?: AccountTargetPermissionsNames,
) => {
  if (!permissionName) return false

  return Boolean(getAccountPermissionsByStatus(state)[permissionName])
}

export const getCurrentAccount: (state: RootState) => IExtendedCurrentAccount = createSelector(
  (state: RootState) => state.app?.currentAccount,
  (currentAccount) => ({
    ...currentAccount,
    isFree: AccountModel.isFree(currentAccount),
    isPro: AccountModel.isProOrTrial(currentAccount),
    isVipPro: AccountModel.isVipProStatus(currentAccount),
    isExpired: AccountModel.isExpired(currentAccount),
    isTrial: AccountModel.isTrial(currentAccount),
  }),
)

export const getIsPro = createSelector(getCurrentAccount, (user) => user.isPro)

export const getIsVipPro = createSelector(getCurrentAccount, (user) => user.isVipPro)

export const getPricingLimitInfo = (state: RootState): PricingLimitInfo => {
  const { subscribers_total, subscribers_limit } = getCurrentAccount(state)

  const MIN_LIMIT_TO_BLOCK = 200
  const LIMIT_FALLBACK = 1000

  return {
    subscribers_total: subscribers_total || 0,
    subscribers_limit: subscribers_limit || LIMIT_FALLBACK,
    MIN_LIMIT_TO_BLOCK,
  }
}

export const getSelfServeSegment = (state: RootState) => {
  return getCurrentAccount(state).self_serf
}

export const getDefaultReply = (state: RootState) => {
  return state.defaultReply
}

export const getHasAnyBroadcasts = (state: RootState): boolean => {
  return getCurrentAccount(state).has_any_broadcasts
}

export const getCanSubscribersBulkDeletion = (state: RootState): boolean => {
  return getCurrentAccount(state).can_subscribers_bulk_deletion || false
}

export const getAfterExpiredPeriod = (state: RootState): number | null => {
  return getCurrentAccount(state).block_after_expire_ts
}

export const getBlockLimits = (state: RootState) => {
  return getCurrentAccount(state).block_limits || {}
}

export const isTrialAvailable = (state: RootState) => {
  const currentAccount = getCurrentAccount(state)

  return Boolean(currentAccount.isFree && currentAccount.is_trial_available)
}

export const getAsyncBillingActions = (state: RootState) => {
  return getCurrentAccount(state).billing_next_action_required?.redirect_to_url || null
}

export const getCurrentAccountProducts = (state: RootState) => {
  return getCurrentAccount(state).products
}

export const getHasInboxProduct = (state: RootState) =>
  Boolean(getCurrentAccountProducts(state)?.has_inbox)

export const getCurrentAccountProductList = (state: RootState): ProProduct[] => {
  const currentAccountProducts = getCurrentAccountProducts(state)

  if (!currentAccountProducts?.has_inbox && !currentAccountProducts?.has_automation) {
    return [ProductType.INBOX, ProductType.AUTOMATION]
  }

  const productList: ProProduct[] = []
  if (currentAccountProducts?.has_inbox) {
    productList.push(ProductType.INBOX)
  }
  if (currentAccountProducts?.has_automation) {
    productList.push(ProductType.AUTOMATION)
  }

  return productList
}

export const isInboxOnlyAccount = (state: RootState): boolean => {
  const products = getCurrentAccountProducts(state)

  if (!products) {
    return false
  }

  return products.has_inbox && !products.has_automation
}

export const getTeamMemberLimit = (state: RootState) => {
  return getBlockLimits(state).users
}

export const getBlockedReasonsMessage = (state: RootState): string => {
  const blockReasons = getCurrentAccount(state).block_reasons || []
  const { keywords, users, contacts } = getBlockLimits(state)
  const limitChain = [
    blockReasons.includes('contacts') &&
      l.translate('{contacts, plural, one {# contact} other {# contacts}}', { contacts }),
    blockReasons.includes('keywords') &&
      l.translate('{keywords, plural, one {# custom Keyword} other {# custom Keywords}}', {
        keywords,
      }),
    blockReasons.includes('users') &&
      l.translate('{users, plural, one {# team member} other {# team members}}', {
        users,
      }),
  ]
  return limitChain.filter(Boolean).join(', ')
}

export const getIsAfterExpiredPeriod = (state: RootState): boolean => {
  const accountStatus = getAccountStatus(state)

  return Boolean(getAfterExpiredPeriod(state) && isExpiredStatus(accountStatus))
}

export const getDaysBeforeBlocked = (state: RootState): number | null => {
  const afterExpiredPeriodEndDateTs = getAfterExpiredPeriod(state)
  if (!afterExpiredPeriodEndDateTs) return null

  const millisecondsBeforeBlocked = afterExpiredPeriodEndDateTs * 1000 - Date.now()
  return Math.floor(millisecondsBeforeBlocked / 1000 / 60 / 60 / 24)
}

export const getCurrentAccountUser = (state: RootState): AccountUser => state.app.currentAccountUser

export const getLivechatSettings = (state: RootState): LivechatSettingsInfo =>
  getCurrentAccountUser(state)?.livechat_settings ?? null

export const getAccountsCount = (state: RootState): number => state.app.accountsCount ?? 0

export const getAccounts = (state: RootState): ISidebarAccount[] => state.app.accounts || []

export const getAccountsWithoutCurrent = createSelector(
  [
    (state: RootState) => state.app.accounts || [],
    (state: RootState) => getCurrentAccountID(state),
  ],
  (accounts, currentAccountID) => {
    return accounts.filter(({ id }) => id !== currentAccountID)
  },
)

export const getPages = (state: RootState): IPage[] => state.app.pages.items || []

export const getAccountById = (state: RootState, id: string): ISidebarAccount | null =>
  getAccounts(state).find((a: ISidebarAccount) => a.page_id === id) || null

export const getUser = (state: RootState): User => state.app?.user

export const getUserFullName = createSelector(getUser, (user) => user.full_name)

export const getConfirmedUserActivity = createSelector(
  getUser,
  (user): UserActivity => user?.activity ?? initialState,
)

export const getProfileDashboardStatus = createSelector(
  getUser,
  (user) => user.has_pages_dashboards ?? false,
)

export const getRegistrationSource = createSelector(getUser, (user) => user.reg_source)

const emptyFlags = {}

export const getAccountFlags: Selector<RootState, Record<string, boolean>> = createSelector(
  [getCurrentAccount],
  (account) => account.flags || emptyFlags,
)

export const getIsAccountFlagEnabled = createSelector(
  [getAccountFlags, (_, flag: AccountFlag) => flag],
  (accountFlags, flag) => Boolean(accountFlags[flag]),
)

export const getUserFlags = createSelector(getUser, (user) => user.flags || emptyFlags)

export const getIsUserFlagEnabled = createSelector(
  [getUserFlags, (_, flag: UserFlagValues) => flag],
  (userFlags, flag) => Boolean(userFlags[flag]),
)

export const getUserId = (state: RootState): number | null => getUser(state)?.user_id || null

export const getUserFirstName = (state: RootState): string => getUser(state)?.first_name ?? ''

export const getUserLastName = createSelector(getUser, (user) => user?.last_name ?? '')

export const getUserTimeZone = (state: RootState): string | null => getUser(state)?.timezone ?? null

export const getAvatarSmall = (state: RootState): string | null =>
  getUser(state)?.avatar_small ?? null

export const getUserInterfaceData = (state: RootState) => getUser(state)?.interface_data

export const getUserInterfaceDataDisplayFlags = (state: RootState) =>
  getUserInterfaceData(state)?.display_flags

export const getLoggedIn = (state: RootState): boolean => Boolean(getUserId(state))

export const getPersona = (state: RootState): Persona => getCurrentAccountUser(state).persona_api

export const isSkipPreviewOnboardingModal = createSelector(
  getUser,
  (user) => user.skip_preview_onboarding_modal,
)

export const getHasBillingAccess = createSelector(
  getCurrentAccountUser,
  (user) => user.has_billing_access,
)

export const isUserCreator = (state: RootState): boolean =>
  getCurrentAccountUser(state)?.is_creator || false

export const isIntegrationConnected = (state: RootState, integration: string): boolean =>
  Boolean(getCurrentAccount(state).connected_integrations?.[integration])

export const getBotTimezone = (state: RootState): string | null =>
  getCurrentAccount(state).timezone || null

export const getPageId = (state: RootState): string | null =>
  getCurrentAccount(state).page_id || null

export const getCurrentAccountID = (state: RootState): string => getCurrentAccount(state).id || ''

export const getCsrfToken = (state: RootState): string | null => state.app?.csrf_token ?? null

export const getInstalledApplications = createSelector(
  [getCurrentAccount],
  (currentAccount) => currentAccount.installed_applications || [],
)

export const getApplicationById =
  (app_id: number | null) =>
  (state: RootState): IInstalledApplication | null =>
    getInstalledApplications(state).find(
      (application: IInstalledApplication) => application.app_id === app_id,
    ) || null

export const getApplicationActionTitleByName =
  (app_id: number, name?: string) =>
  (state: RootState): string =>
    getApplicationById(app_id)(state)?.actions?.find(
      (action: IApplicationAction) => action.name === name,
    )?.title || ''

export const isRelogin = (state: RootState): boolean => {
  const user = getUser(state)
  return Boolean(user?.is_relogin)
}

export const getFBPolicyEnforcementNotifications = (
  state: RootState,
): FacebookPolicyEnforcementNotification[] =>
  sortBy(state.app.currentAccountUser?.fbPolicyEnforcementNotifications || [], 'notification_id')

export const getFBPolicyEnforcementState = (
  state: RootState,
): FacebookPolicyEnforcementState | null => {
  const notifications = getFBPolicyEnforcementNotifications(state)
  if (!notifications.length) {
    return null
  }

  const notification = notifications[notifications.length - 1]
  if (notification.type === FBPolicyEnforcementNotificationType.BLOCK) {
    return {
      type: FBPolicyEnforcementModalType.BLOCK,
      notification,
    }
  }

  if (notification.type === FBPolicyEnforcementNotificationType.WARNING) {
    return {
      type: FBPolicyEnforcementModalType.WARNING,
      notification,
    }
  }

  const blockNotification = notifications.find(
    (n) => n.type === FBPolicyEnforcementNotificationType.BLOCK,
  )
  if (notification.type === FBPolicyEnforcementNotificationType.UNBLOCK && blockNotification) {
    return {
      type: FBPolicyEnforcementModalType.WAS_BLOCK,
      notification: blockNotification,
    }
  }

  return null
}

export const getUserEmailUnverified = (state: RootState): string => {
  return getUser(state).email_unverified || ''
}

export const getUserEmail = (state: RootState): string => {
  return getUser(state).email || ''
}

export const getFbAppId = (state: RootState): string => {
  return state.app.FBAPP_ID
}

export const getAllowedLanguages = (state: RootState) => {
  return getUser(state)?.allowedLanguages || state.app.allowedLanguages || []
}

export const getUserLanguage = (state: RootState) => {
  return getUser(state)?.language || getLangFromCookie()
}

export const hasQuickCampaignsAccess = (state: RootState) => {
  return getUser(state).has_quick_campaigns_access
}

export const getClosedNotificationIds = (state: RootState) => state.app.closedNotificationIds

export const getAccountsDisplayFlags = (state: RootState) =>
  getCurrentAccount(state).interface_data?.display_flags

export const isMessageTagsActivated = (state: RootState) =>
  !getAccountsDisplayFlags(state)?.[AccountDisplayFlag.SHOW_MESSAGE_TAGS_ACTIVATION_BUTTON]

export const isRnFlowFollowed = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.RN_DEFAULT_FLOW_CREATED] ?? false

export const isIGRnFlowFollowed = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.IG_RN_DEFAULT_FLOW_CREATED] ?? false

export const isWizardFinished = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.RN_WIZARD_FINISHED] ?? false

export const isIGWizardFinished = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.IG_RN_WIZARD_FINISHED] ?? false

export const getIsHomeTabTemplatesGenerated = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.GENERATED_HOME_TAB_TEMPLATES] ?? false

export const getShowCgtTemplatesOnHomeTab = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.SHOW_CGT_TEMPLATES_ON_HOME_TAB] ?? false

export const getShowDmTemplatesOnHomeTab = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.SHOW_DM_TEMPLATES_ON_HOME_TAB] ?? false

export const getShowStoryTemplatesOnHomeTab = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.SHOW_STORY_TEMPLATES_ON_HOME_TAB] ?? false

export const getShowLiveTemplatesOnHomeTab = (state: RootState) =>
  getAccountsDisplayFlags(state)?.[AccountDisplayFlag.SHOW_LIVE_TEMPLATES_ON_HOME_TAB] ?? false

export const getIsZapierEnabled = (state: RootState) => getCurrentAccount(state).enable_zapier

export const getThreadScoringThreshold = (state: RootState) => {
  return getCurrentAccount(state).thread_scoring_threshold
}

export const getActualAppVersion = (state: RootState) => state.app.actualAppVersion

export const getConnectedIntegrations = (state: RootState) =>
  getCurrentAccount(state).connected_integrations

export const getIsIntegrationTikTokAdsConnected = (state: RootState) =>
  Boolean(getConnectedIntegrations(state).tiktok_ads)

export const getActiveIgTrialActivationFlag = createSelector([getAccountFlags], (accountFlags) => {
  const igTrialFlags = [
    AccountFlag.IG_TRIAL_ACTIVATION_TEST_1,
    AccountFlag.IG_TRIAL_ACTIVATION_TEST_2,
    AccountFlag.IG_TRIAL_ACTIVATION_CONTROL,
  ]

  return igTrialFlags.find((flag) => Boolean(accountFlags[flag])) || null
})

export const getInAppCardAttachmentFlags = createSelector([getAccountFlags], (flags) => ({
  isEnabled: Boolean(flags?.[AccountFlag.ENABLE_IN_APP_CARD_ATTACHMENT]),
  isDisabled: Boolean(flags?.[AccountFlag.DISABLE_IN_APP_CARD_ATTACHMENT]),
}))
