import { Stripe } from '@stripe/stripe-js'
import { l } from '@manychat/manyui'

import { confirmIntent } from 'common/billing/actions/stripeCheckoutActions'
import { alert } from 'common/core'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import {
  StripeCheckoutSuccessSubscribeClientFlowPayload,
  StripeConfirmedIntentSchema,
} from 'shared/api/requests/billingFlex/schemas'
import { createAsyncAction } from 'shared/lib/redux'

export const checkoutSuccessSubscribeClientFlow = createAsyncAction<
  StripeConfirmedIntentSchema,
  StripeCheckoutSuccessSubscribeClientFlowPayload & { stripe: Stripe }
>('billingFlex/checkoutSuccessSubscribeClientFlow', async (payload) => {
  const response = await billingFlexApi.checkoutSuccessSubscribeClientFlow({
    body: {
      setup_intent_id: payload.setup_intent_id,
      products: payload.products,
      addons: payload.addons,
    },
  })

  const { type, client_secret } = response.data.confirm

  const confirmResult = await confirmIntent({
    stripe: payload.stripe,
    type,
    client_secret,
  })

  alert(l.translate('Successfully updated'), 'success')
  return confirmResult
})
