import { api } from 'shared/api'
import { billingFlexApiSchemas } from 'shared/api/requests/billingFlex/schemas'

export const billingFlexApi = {
  checkoutAndConnectCard: api.account.createPost({
    url: '/billingFlex/checkoutAndConnectCard',
    schemas: billingFlexApiSchemas.checkoutAndConnectCard,
  }),
  checkoutAndSubscribe: api.account.createPost({
    url: '/billingFlex/checkoutAndSubscribe',
    schemas: billingFlexApiSchemas.checkoutAndSubscribe,
  }),
  getPlansTiers: api.account.createGet({
    url: '/billingFlex/getPlansTiers',
    schemas: billingFlexApiSchemas.plansTiers,
  }),
  manageSubscription: api.account.createPost({
    url: '/billingFlex/manageSubscription',
    schemas: billingFlexApiSchemas.manageSubscription,
  }),
  updateTaxNumber: api.account.createPost({
    url: '/billingFlex/updateTaxNumber',
    schemas: billingFlexApiSchemas.updateTaxNumber,
  }),
  stripeCreateCustomer: api.account.createPost({
    url: '/billingFlex/stripeCreateCustomer',
    schemas: billingFlexApiSchemas.stripeCreateCustomer,
  }),
  checkoutSuccessSubscribeClientFlow: api.account.createPost({
    url: '/billingFlex/checkoutSuccessSubscribeClientFlow',
    schemas: billingFlexApiSchemas.checkoutSuccessSubscribeClientFlow,
  }),
}
